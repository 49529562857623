






















import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { AxiosInstance } from 'node_modules/axios'

export default defineComponent({
  setup(_, { root }) {
    const form = ref<any>(null)

    const state = reactive({
      open: computed(() => root.$store.getters['user/getTosDialog']),
      valid: false,
      loading: false,
    })

    const model = reactive({
      checkbox: false
    })

    const rules: { [key: string]: ((v: any) => string | boolean)[] } = {
      checkbox: [v => !!v || 'Musisz zaakceptować Politykę Prywatności i Regulamin Aplikacji CleverFleet, aby kontunuować.']
    }

    const logout = () => {
      root.$store.dispatch('user/logout').then(() => {
        root.$router.push({ name: 'auth' })
      })
    }

    const accept = async () => {
      await form.value?.validate()

      if (state.valid) {
        const $axios = root.$store.getters['api/getInstance'] as AxiosInstance

        state.loading = true

        $axios
          .put('/user/current/accept-terms')
          .then(() => root.$store.commit('user/SET_TOS', false))
          .catch(console.log)
          .finally(() => (state.loading = false))
      }
    }

    return { form, state, model, rules, logout, accept }
  },
})
